import ComponentStore from "../../../utils/ComponentStore";
import DataListConfig from "./config/DataListConfig";
import {useQuery} from "@apollo/client";
import {GET_ON_OFF_VALUES} from "../../../data/queries/sensors";
import {client} from "../../../utils/Client";
import {useMemo, useState} from "react";
import moment from "moment/moment";

export default function DataList(props) {
    const dateFrom = useMemo(() => moment().subtract(props.timeframe * 2, "seconds"), [props.timeframe])
    const dateTo = useMemo(() => new Date().toISOString(), [])

    const [values, setValues] = useState([])

    const {data, loading, error} = useQuery(GET_ON_OFF_VALUES, {
        variables: {
            sensors: props.sensors.map(s => s.sensor),
            dateFrom: dateFrom,
            dateTo: dateTo,
            threshold: props.threshold,
        },
        pollInterval: props.pollInterval,
        client,
        onCompleted: (data) => {
            console.log("DataList got data: ", data)
        }
    })

    return (
        <div>test</div>
    )

}

ComponentStore.registerItem("DataList", DataList, "Data List", DataListConfig);
