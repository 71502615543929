import {gql} from "@apollo/client";

export const GET_DASHBOARDS = gql`
    query GetDashboards {
        dashboards {
            edges {
                node {
                    id
                    key
                    name
                    description
                    priority
                }
            }
        }
    }
`

export const GET_DASHBOARD_BY_KEY = gql`
    query GetDashboard($key: String!) {
        dashboardByKey(key: $key) {
            id
            key
            name
            description
            priority
            config
            items {
                edges {
                    node {
                        id
                        pk
                        name
                        config
                        position
                        parent {
                            id
                        }
                        children {
                            edges {
                                node {
                                    id
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`

export const GET_ITEM = gql`
    query GetItem($id: ID!) {
        item(id: $id) {
            id
            pk
            name
            config
            position
            childrenCount
            dashboard {
                id
            }
            children {
                edges {
                    node {
                        id
                        position
                    }
                }
            }
        }
    }
`

export const GET_CHILD_ITEMS = gql`
    query GetItem($parent: ID!) {
        item(id: $parent) {
            id
            dashboard {
                id
            }
            children {
                edges {
                    node {
                        id
                        name
                        pk
                        config
                        position
                    }
                }
            }
        }
    }
`
