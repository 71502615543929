import Preview from "../../Preview";
import TextField from "../TextField";
import {Col, Form, Row} from "react-bootstrap";
import {useConfig} from "../../../../utils/hooks";

export default function TextFieldConfig(props) {
    const {config, setValue} = useConfig(props.config, {
        text: "",
    }, props.onConfigChange)


    return (
        <div>
            <Form>
                <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm={3}>Text</Form.Label>
                    <Col sm={9}>
                        <Form.Control as="textarea" defaultValue={config.text} onChange={(ev) => setValue('text', ev.target.value) } />
                    </Col>
                </Form.Group>
            </Form>
            <Preview>
                <TextField {...config} />
            </Preview>
        </div>
    )
}
