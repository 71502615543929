import './App.scss';
import {Link, Route, Switch} from "react-router-dom";
import {Container, Nav, Navbar, Spinner, Alert, Dropdown} from "react-bootstrap";
import React, {Fragment, useRef} from "react";
import {Redirect} from "react-router-dom";
import LoginForm from "components/LoginForm";
import {useQuery} from "@apollo/client";
import {USER_INFO} from "../data/queries/authentication";
import {logout} from "../utils/Client";
import { PersonCircle, Speedometer, BoxArrowInRight, BoxArrowRight } from 'react-bootstrap-icons';
import DashboardPage from "./DashboardPage";
import LoginPage from "./LoginPage";
import DashboardMenu from "../components/dashboard/DashboardMenu";

import Logo from "resources/logo.png";


function App() {
    const {data, refetch, loading, error} = useQuery(USER_INFO)

    const dropdownRef = useRef()

    const handleLogout = () => {
        logout()
        refetch()
    }

    if (loading) return <Spinner animation="border" />
    if (error) return <Alert variant="danger">{error}</Alert>

    console.log(dropdownRef.current)
  return (
      <Fragment>
              <Navbar variant="dark" bg="primary" className="sticky-top" style={{boxShadow: "0 0 10px #343a40"}}>
                  <Navbar.Brand as={Link} to="/dashboard"><img src={Logo} width={30} height={30} className="d-inline-block align-top ms-4" alt="Dachsberger Portal" /></Navbar.Brand>
                  <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                  <Navbar.Collapse id="responsive-navbar-nav">
                      <Nav className="me-auto">
                          <Dropdown className="d-inline mx-2">
                              <Dropdown.Toggle split drop="start" as={Nav.Link}><Speedometer /> Dashboards </Dropdown.Toggle>
                              <Dropdown.Menu align="start" variant="light" className="mb-0">
                                  <DashboardMenu />
                              </Dropdown.Menu>
                          </Dropdown>
                      </Nav>

                          {data.me
                              ? (
                                <Nav>
                                    <Dropdown as={Nav.Item} >
                                        <Dropdown.Toggle drop="start" as={Nav.Link}><PersonCircle /> {data.me.username}</Dropdown.Toggle>
                                        <Dropdown.Menu align="end">
                                            <Dropdown.Item onClick={handleLogout}><BoxArrowRight /> Abmelden</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </Nav>
                              )
                              : (
                                <Nav>
                                    <Dropdown as={Nav.Item}>
                                        <Dropdown.Toggle drop="start" as={Nav.Link}><BoxArrowInRight /> Login</Dropdown.Toggle>
                                        <Dropdown.Menu align="end"  ref={dropdownRef}>
                                            <div className="m-2" style={{width: 250}}><LoginForm /></div>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </Nav>
                              )
                          }

                  </Navbar.Collapse>
              </Navbar>

          <Container fluid className="p-0 h-100">
          <Switch>
              <Route exact path="/" render={() => {
                  return (
                      <Redirect to="/dashboard" />
                  )
              }} />
              <Route path="/login" component={LoginPage} />
              <Route exact path="/dashboard" component={DashboardPage} />
              <Route path="/dashboard/:key" component={DashboardPage} />
          </Switch>
          </Container>
          <div id="modal_dialog" />
          <div id="backdrop" hidden style={{position: "fixed", zIndex: 4000, top: 0, left: 0, right: 0, bottom: 0, backgroundColor: "#000", opacity: .9}}/>
      </Fragment>
  );
}

export default App;
