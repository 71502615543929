import React, {useContext, useState} from "react";
import {Card, Dropdown} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import {} from "./AddItemButton.scss";
import ComponentStore from "../../utils/ComponentStore";
import ItemConfigDialog from "./ItemConfigDialog";

export default function AddItemButton({dashboard, parent=null, position=0}) {
    const [item, setItem] = useState(null)
    const [show, setShow] = useState(false)

    const components = ComponentStore.getItems()

    const createComponent = event => {
        setItem({
            dashboard,
            parent,
            name: event.target.dataset.item,
            position,
            config: {}
        })
        showDialog()
    }

    const showDialog = () => {
        setShow(true)
    }

    const hideDialog = () => {
        setShow(false)
    }

    return (
        <React.Fragment>
            <Card className="AddItemButton m-0 text-center d-flex align-items-center justify-content-center">
                <Dropdown>
                    <Dropdown.Toggle bsPrefix="w-100" variant="outline-primary"><div className="btn-block w-100"><FontAwesomeIcon icon={faPlus} /></div></Dropdown.Toggle>
                    <Dropdown.Menu>
                        {Object.keys(components).map(el => (
                            <Dropdown.Item data-item={el} onClick={createComponent}>{components[el].description}</Dropdown.Item>
                        ))}
                    </Dropdown.Menu>
                </Dropdown>
            </Card>
            {!!show && !!item &&
                <ItemConfigDialog item={item} onClose={hideDialog} create />
            }
        </React.Fragment>
    )
}
