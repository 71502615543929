import React, {useEffect, useState, Fragment} from "react";
import PropTypes from "prop-types";
import {faEdit} from "@fortawesome/free-regular-svg-icons";
import {Alert, Button, Spinner, Modal} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrash} from "@fortawesome/free-solid-svg-icons";
import {} from "./DashboardItem.scss";
import {useMutation, useQuery} from "@apollo/client";
import {GET_ITEM} from "../../data/queries/dashboard";
import ComponentStore from "../../utils/ComponentStore";
import {DELETE_ITEM, UPDATE_ITEM} from "../../data/mutations/dashboard";
import {toast} from "react-hot-toast";
import ItemConfigDialog from "./ItemConfigDialog";
import {Draggable} from "react-beautiful-dnd";
import {ErrorBoundary} from "@sentry/react";

export default function DashBoardItem({dashboard, itemId, editMode=false, fill=false, itemHeight='auto', height='auto', minHeight='10px', maxHeight='auto'}) {
    const {data, loading, error} = useQuery(GET_ITEM, {
        variables: {
            id: itemId
        }
    })

    const [deleteItem] = useMutation(DELETE_ITEM)

    const [item, setItem] = useState({})
    const [child, setChild] = useState(<Spinner animation="border" />)
    const [showConfig, setShowConfig] = useState(false)
    const [showDeleteDialog, setShowDeleteDialog] = useState(false)

    useEffect(() => {
        if (data && data.item) {
            const d = {...data.item}
            d.config = JSON.parse(d.config)
            setItem(d)
        }
    }, [data])

    useEffect(() => {
        if (item) {
            setChild(ComponentStore.createComponent(item.name, {...item.config, dashboard: item.dashboard, editMode, id: itemId, height}))
        }
    }, [item, editMode])

    const showConfigDialog = () => {
        setShowConfig(true)
    }

    const hideConfigDialog = () => {
        setShowConfig(false)
    }

    const deleteItemConfirm = () => {
        toast.promise(
            deleteItem({
                variables: {
                    id: itemId
                }
            }),
            {
                loading: () => "Wird gelöscht...",
                success: data => {
                    if (data.data.deleteItem.found) {
                        document.dispatchEvent(new Event('dashboard.reload'))
                        return "Element wurde gelöscht."
                    } else {
                        return "Element nicht gefunden."
                    }
                },
                error: error => {
                    return `Fehler: ${error.message}`
                }
            })
    }

    if (loading && !item) return <Spinner animation="border" />
    if (error) {
        console.error(error)
        return <Alert variant="danger">{error}</Alert>
    }

    return (
            <div className={`dashboard-item d-flex flex-column w-xs-100 ${fill ? "flex-even" : ""}` }
                 >
                {!!editMode &&
                    <div className="button-container d-flex justify-content-between border text-muted">
                        <div className="dashboard-item-header">
                            <strong>{item && item.name}</strong>&nbsp;
                        </div>
                        <div>
                            <Button  onClick={showConfigDialog} size="xs"
                                     variant="outline-primary" className=""><FontAwesomeIcon icon={faEdit}
                                                                                             size="xs"/></Button>
                            <Button onClick={() => setShowDeleteDialog(true)} size="xs"
                                    variant="outline-danger" className=""><FontAwesomeIcon icon={faTrash}
                                                                                           size="xs"/></Button>
                        </div>
                        <Modal show={showDeleteDialog}>
                            <Modal.Header>
                                <Modal.Title>Objekt löschen</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <p>Das Objekt "{item.name}" wirklich löschen?</p>
                                { item.childrenCount > 0 &&
                                    <p><strong>
                                        Dieses Objekt hat {item.childrenCount} untergeordnete Objekte.<br/>Diese werden
                                        dadurch auch gelöscht.
                                    </strong></p>
                                }
                            </Modal.Body>
                            <Modal.Footer className="d-flex justify-content-between">
                                <Button variant="secondary" onClick={() => setShowDeleteDialog(false)}>Nein</Button>
                                <Button variant="danger" onClick={deleteItemConfirm}>Ja</Button>
                            </Modal.Footer>
                        </Modal>
                    </div>
                }
                <div className="dashboard-item-content flex-grow-0"  style={{height, minHeight, maxHeight}}>
                    <ErrorBoundary fallback={<div>Element kann nicht geladen werden</div>}>
                        {child}
                    </ErrorBoundary>
                </div>
                {!!showConfig &&
                    <ItemConfigDialog item={item} onClose={hideConfigDialog} />
                }
            </div>

    )
}
