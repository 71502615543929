import React from "react";
import PropTypes from "prop-types";
import ComponentStore from "../../../utils/ComponentStore";

import TitleHeaderConfig from "./config/TitleHeaderConfig";


export default function TitleHeader(props) {
    return (
        <div className="my-2">
            { props.topLine ? <hr /> : "" }
            <div className={props.size}>{props.title}</div>
            { props.subtitle ? <div className={`lead text-muted ${props.size === 'h5' && "small"}`}>{props.subtitle}</div> : "" }
            { props.bottomLine ? <hr /> : "" }
        </div>
    );
}

TitleHeader.propTypes = {
    topLine: PropTypes.bool,
    bottomLine: PropTypes.bool,
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string,
    size: PropTypes.string,
};

TitleHeader.defaultProps = {
    topLine: false,
    bottomLine: false,
    subtitle: null,
    size: 'h3',
}




ComponentStore.registerItem("TitleHeader", TitleHeader, "Titelzeile", TitleHeaderConfig);
