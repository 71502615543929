import {Button, Col, Form, Modal, Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faUndo} from "@fortawesome/free-solid-svg-icons";
import React, {useState} from "react";
import {useMutation} from "@apollo/client";
import {ADD_DASHBOARD} from "../../data/mutations/dashboard";
import {toast} from "react-hot-toast";
import {useHistory} from "react-router";

export default function NewDashboardDialog({onClose=() => {}}) {
    const [title, setTitle] = useState("")
    const [description, setDescription] = useState("")
    const [priority, setPriority] = useState(0)
    const history = useHistory()

    const [addDashboard] = useMutation(ADD_DASHBOARD)

    const saveDashboard = () => {
        toast.promise(
            addDashboard({
                variables: {
                    name: title,
                    description,
                    priority,
                    config: JSON.stringify({})
                }
            }), {
                loading: "Dashboard wird erstellt.",
                success: data => {
                    onClose()
                    history.push(`/dashboard/${data.data.addDashboard.dashboard.key}`)

                    return `Dashboard ${data.data.addDashboard.dashboard.key} erfolgreich erstellt.`
                },
                error: err => `Fehler: ${err.message}`
            }
        )
    }

    return (
        <Modal onHide={onClose} show={true}>
            <Modal.Header closeButton>
                <Modal.Title>Neues Dashboard erstellen</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm={6}>Titel</Form.Label>
                    <Col sm={6}>
                        <Form.Control required value={title} onChange={ev => setTitle(ev.target.value)}/>
                    </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm={6}>Beschreibung</Form.Label>
                    <Col sm={6}>
                        <Form.Control defaultValue={description} onChange={ev => setDescription(ev.target.value)}/>
                    </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm={6}>Priorität</Form.Label>
                    <Col sm={6}>
                        <Form.Control type="number" value={priority} onChange={ev => setPriority(parseInt(ev.target.value))}/>
                    </Col>
                </Form.Group>
            </Modal.Body>
            <Modal.Footer>
                <Button className="me-2" variant="primary" onClick={saveDashboard}><FontAwesomeIcon icon={faCheck}/> Ok</Button>
                <Button variant="secondary" onClick={onClose}><FontAwesomeIcon icon={faUndo}/> Abbrechen</Button>
            </Modal.Footer>
        </Modal>
    )
}
