import React, {Fragment, useEffect, useMemo, useRef, useState} from "react";
import PropTypes from "prop-types";
import ComponentStore from "../../../utils/ComponentStore";

import {faPlay} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import CameraConfig from "./config/CameraConfig";

import {useVideoPlayer} from "../../../utils/hooks/VideoPlayer";

import Poster from "resources/dachsberger-poster.png"
import ItemWrapper from "../ItemWrapper";

import {secure, host, getToken} from "utils/Client"

export const protocols = {
    rtsp: {
        type: 'application/webrtc',
        schema: 'rtsp',
        port: 554,
        //urlParser: url => `${secure ? 'wss' : 'ws'}://${host}/ws/stream/` + btoa(url),
        urlParser: url => `${secure ? 'https' : 'http'}://${host}/video/` + btoa(url) + "/output.m3u8",
        //urlParser: url => `ws://127.0.0.1:19999/ws/stream/` + btoa(url),
    },
}

export default function Camera(props) {
    const token = getToken()

    const buildStreamUrl = () => {
        const protocol = protocols[props.protocol]
        const url = `${protocol.schema}://${props.username ? `${props.username}${props.password ? `:${props.password}` : ""}@` : ""}${props.address}:${props.port ? props.port : protocol.port}${props.path ? props.path : ""}`
        return protocols.rtsp.urlParser(url) // + `/${token}`
    }

    const [playbackState, setPlaybackState] = useState(null)
    const [fullscreen, setFullscreen] = useState(false);
    const videoRef = useRef()
    const {error, restartPlayback} = useVideoPlayer({
        url: buildStreamUrl(),
        //url: "http://cthulhu.zhaus:2000/video/output.m3u8",
        ref: videoRef,
        useVideo: true,
        useAudio: false
    })

    useEffect(() => {
        console.log("Playback State: ", playbackState)
        if (playbackState === 'ended') {
            restartPlayback()
        }
    }, [playbackState])

    useEffect(() => {
        const setWaiting = () => setPlaybackState("waiting")
        const setPlaying = () => setPlaybackState("playing")
        const setPaused = () => setPlaybackState("paused")
        const setError = () => setPlaybackState("error")
        const setStalled = () => setPlaybackState("stalled")
        const setEnded = () => setPlaybackState("ended")

        if (videoRef && videoRef.current) {
            videoRef.current.addEventListener("waiting", setWaiting)
            videoRef.current.addEventListener("playing", setPlaying)
            videoRef.current.addEventListener("paused", setPaused)
            videoRef.current.addEventListener("error", setError)
            videoRef.current.addEventListener("stalled", setStalled)
            videoRef.current.addEventListener("ended", setEnded)

            return () => {
                videoRef.current.removeEventListener("waiting", setWaiting)
                videoRef.current.removeEventListener("playing", setPlaying)
                videoRef.current.removeEventListener("paused", setPaused)
                videoRef.current.removeEventListener("error", setError)
                videoRef.current.removeEventListener("stalled", setStalled)
                videoRef.current.removeEventListener("ended", setEnded)
            }
        }
    }, [videoRef])

    const styles = {
        video: {
            width: 300,
        },
        videoWrapper: {
        }

    }

    const toggleFullscreen = ev => {
        setFullscreen(!fullscreen);
        document.getElementById('backdrop').hidden = fullscreen;
    }

    const protocol = protocols[props.protocol];
    if (protocol) {
        return (
            <ItemWrapper
                header={
                    <Fragment>
                        <div className="small font-weight-bold text-muted">{props.title || "Video"}</div>
                        <div className="small font-weight-bold text-muted">{playbackState === 'playing' ? <FontAwesomeIcon
                            icon={faPlay}/> : <div className="spinner-border spinner-border-sm"/>}</div>
                    </Fragment>
                }
            >
                <div style={styles.videoWrapper}>
                    {error
                        ? <div className="alert alert-warning">{error}</div>
                        : <video className="w-xs-100" ref={videoRef} playsinline="playsinline" controls={true} muted={true} preload="auto" autoPlay={true}
                               poster={Poster} style={styles.video} />
                    }
                </div>
            </ItemWrapper>
        )
    } else {
        return <div className="my-2"><div className="spinner-border" /></div>
    }
}

Camera.propTypes = {
    address: PropTypes.string
};

Camera.defaultProps = {
    address: "",
    username: "",
    password: "",
    protocol: "rtsp",
    path: "",
    port: null,
}

ComponentStore.registerItem("Camera", Camera, "Kamera", CameraConfig);
