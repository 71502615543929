import {gql} from "@apollo/client";

export const USER_INFO = gql`
    query UserInfo {
        me {
            username
            firstName
            lastName
        }
    }
`
