import React, {Fragment, useEffect, useState} from "react";
import PropTypes from "prop-types";
import AddItemButton from "../AddItemButton";
import ComponentStore from "../../../utils/ComponentStore";
import DashboardItem from "../DashboardItem";
import SensorRowConfig from "./config/SensorRowConfig";
import {GET_CHILD_ITEMS} from "../../../data/queries/dashboard";
import {useQuery} from "@apollo/client";

export default function SensorRow({id, vertical=false, editMode=false, fill=false, spacing=2, wrap=true}) {
    const {data, loading, error} = useQuery(GET_CHILD_ITEMS, {
        variables: {
            parent: id
        }
    })

    const [items, setItems] = useState([])

    useEffect(() => {
        if (data) {
            setItems(data.item.children.edges.map(it => it.node))
        }
    }, [data])

    return (
        <div className={`w-100 d-flex flex-${wrap ? "" : "no"}wrap justify-content-start gap-${spacing} align-content-start ${vertical ? "flex-column" : "flex-row"}`}>

            {!!editMode && <AddItemButton position={0} parent={id} dashboard={data.item.dashboard.id} />}
            {items.map(item => (
                <Fragment key={item.id}>
                    <DashboardItem fill={fill} itemId={item.id} editMode={editMode} dashboard={data.item.dashboard.id} itemHeight="100%" />
                    {!!editMode ? <AddItemButton position={item.position + 1} parent={id} dashboard={data.item.dashboard.id} /> : <div className={`m${vertical ? "b" : "r"}-${spacing}`} />}
                </Fragment>
            ))}
        </div>
    )
}

SensorRow.description = "Reihe";

ComponentStore.registerItem("SensorRow", SensorRow, SensorRow.description, SensorRowConfig);
