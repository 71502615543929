import React from "react";
import NotAvailable from "../components/dashboard/items/NotAvailable";

export default class ComponentStore {
    static instance = null;
    static items = {

    }

    static createInstance() {
        return new ComponentStore();
    }

    static getInstance() {
        if(!ComponentStore.instance) {
            ComponentStore.instance = ComponentStore.createInstance();
        }
        return ComponentStore.instance;
    }

    static registerItem(name, element, description, createConfig = () => { return {} }) {
        ComponentStore.items[name] = {name, element, description, createConfig};
    }

    static getItem(name) {
        if (name in ComponentStore.items) {
            return ComponentStore.items[name];
        } else {
            return {
                name,
                element: NotAvailable,
                description: "Nicht verfügbar",
                createConfig: () => {return {name}},
        }
        }
    }

    static findItemByComponent(component) {
        return Object.values(ComponentStore.items).filter(c => c.element.type === component.type).shift();
    }

    static getItems() {
        return ComponentStore.items;
    }

    static createConfigComponent(name, config, onConfigChange) {
        const component = ComponentStore.getItem(name).createConfig
        return React.createElement(component, {config, onConfigChange})
    }

    static createComponent(name, config) {
        const component = ComponentStore.getItem(name).element;
        config = config ? config : {};
        return React.createElement(component, {...config});
    }
}
