import { ApolloClient, InMemoryCache, createHttpLink } from "@apollo/client";
import { setContext } from '@apollo/client/link/context';
import { ApolloLink } from "apollo-link";
import {LOGIN, REFRESH_AUTH} from "../data/mutations/authentication";
import {onError} from "@apollo/client/link/error";
import {toast} from "react-hot-toast";

export const protocol = window.location.protocol
export const secure = window.location.protocol.startsWith('https')
export const host = window.location.host

const API_URL = `${protocol}//${host}/graphql/`
const TOKEN_KEY = "AuthToken"

const httpLink = createHttpLink({
    uri: API_URL,
});

const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    const tokens = getTokens()
    // return the headers to the context so httpLink can read them
    return {
        headers: {
            ...headers,
            authorization: tokens ? `JWT ${tokens.accessToken}` : "",

        }
    }
});

const errorLink = onError(({graphQLErrors, networkError}) => {
    if (graphQLErrors) {
        graphQLErrors.forEach(err => {
            switch(err.message) {
                case 'UNAUTHENTICATED':
                    window.location.href = '/login?next=' + window.location.pathname
                    break;
                default:
                    break;
            }
        })
    }
    if (networkError) {
        console.log("network error", networkError)
    }
})

function saveTokens(tokens) {
    localStorage.setItem(TOKEN_KEY, JSON.stringify(tokens))
}

function getTokens() {
    if (localStorage.getItem(TOKEN_KEY))
        return JSON.parse(localStorage.getItem(TOKEN_KEY))
    return null
}

export function getToken() {
    const tokens = getTokens()
    if (tokens) {
        return tokens.accessToken
    } else {
        return null
    }
}

function deleteTokens() {
    localStorage.removeItem(TOKEN_KEY)
}

export function saveUserInfo(info) {
    localStorage.setItem("UserInfo", JSON.stringify(info))
}

export function getUserInfo() {
    const info = localStorage.getItem("UserInfo")
    if (info) return JSON.parse(info)
    return null
}

export function deleteUserInfo() {
    localStorage.removeItem("UserInfo")
}

export const client = new ApolloClient({
    cache: new InMemoryCache(),
    link: ApolloLink.from([authLink, errorLink, httpLink]),
    defaultOptions: {
        query: {
            errorPolicy: 'all'
        },
        mutate: {
            errorPolicy: 'all'
        },
        watchQuery: {
            errorPolicy: 'all'
        }
    }
});

export function refreshAuthToken() {
    const tokens = getTokens()
    if (tokens) {
        client.mutate({
            mutation: REFRESH_AUTH,
            variables: {
                refreshToken: getTokens().refreshToken
            }
        }).then(({data}) => {
            if (data.refreshAuth) {
                const {token, refreshToken} = data.refreshAuth
                saveTokens({accessToken: token, refreshToken})
            } else {
                throw Error(data.errors ? data.errors : "Unknown error")
            }
        }).catch(err => {
            console.error("refresh token error:", err)
        })
    }
}

export const logout = () => {
    deleteTokens()
    dispatchEvent(new CustomEvent('logout'))
}

export const login = (username, password) => {
    client.resetStore()
    return client.mutate({
        mutation: LOGIN,
        variables: {
            username,
            password
        }
    }).then(({data}) => {
        const result = data.tokenAuth
        if (result && result.success) {
            saveTokens({
                accessToken: result.token,
                refreshToken: result.refreshToken
            })
            saveUserInfo(result.user)
            dispatchEvent(new CustomEvent('login.success'))
        }

        return result
    }).catch(err => {
        console.log("login error:", err)
        dispatchEvent(new CustomEvent('login.error'))
    })
}
