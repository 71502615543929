import React, {useEffect, useState} from "react";
import {useQuery} from "@apollo/client";

import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';
import RangeSlider from 'react-bootstrap-range-slider';

import {
    Button,
    Col,
    Dropdown,
    Form,
    FormControl,
    FormSelect,
    InputGroup,
    Row,
    Spinner
} from "react-bootstrap";

import "react-bootstrap-submenu/dist/index.css";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import {DropdownSubmenu} from "react-bootstrap-submenu";

import {useConfig} from "utils/hooks";
import {GET_DATASOURCES} from "data/queries/datasources";
import {client} from "utils/Client";
import Chart, {getStrokeColor} from "../Chart";
import {timeframes} from "../../../../data/consts";


export default function ChartConfig(props) {
    const [datasources, setDatasources] = useState([]);
    const {config, setValue} = useConfig(props.config, {
        sensors: [],
        width: 1,
        height: 1,
        timeframe: timeframes[0].value,
        title: "",
        alertMinimum: null,
        alertMaximum: null,
        updateInterval: 30000,
        floorMinutes: true,
        datapoints: 40,
    }, props.onConfigChange)

    const {data, loading, error} = useQuery(GET_DATASOURCES, {
        client
    })

    useEffect(() => {
        if (data) {
            setDatasources(data.datasources.edges)
        }
    }, [data])

    const getDsById = dsId => {
        const ds = datasources.filter(ds => ds.node.pk === dsId);
        if (ds.length === 1)
            return ds[0].node
        else
            return {}
    }

    const getSensorById = (dsId, sensorId) => {
        const ds = getDsById(dsId);
        if (ds.sensors && ds.sensors.edges.length > 0) {
            const sensor = ds.sensors.edges.filter(s => s.node.pk === sensorId);
            if (sensor.length === 1)
                return sensor[0].node
        }
        return {}
    }

    if (error) return <div>Error</div>
    if (loading || !data) return <Spinner animation="border" />

    return (
        <div>
            <Form>

                <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm={6}>Titel</Form.Label>
                    <Col sm={6}>
                        <Form.Control defaultValue={config.title} onChange={ev => {
                            setValue('title', ev.target.value);
                        }} />
                    </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm={6}>Aktualisierungsintervall (Sekunden)</Form.Label>
                    <Col sm={6}>
                        <Form.Control type="number" defaultValue={parseInt(config.updateInterval) / 1000} onChange={ev => {
                            setValue('updateInterval', parseInt(ev.target.value) * 1000);
                        }} />
                    </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm={6}>Zeitraum</Form.Label>
                    <Col sm={6}>
                        <FormSelect onChange={ev => {
                            setValue('timeframe', parseInt(ev.target.value));
                        }}>
                            {timeframes.map(tf =>
                                <option selected={tf.value === config.timeframe} value={tf.value}>{tf.name}</option>
                            )}
                        </FormSelect>
                    </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm={6}>Datenpunkte</Form.Label>
                    <Col sm={6}>
                        <Form.Control type="number" defaultValue={parseInt(config.datapoints)} onChange={ev => {
                            setValue('datapoints', parseInt(ev.target.value));
                        }} />
                    </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm={6}>Minimum</Form.Label>
                    <Col sm={6}>
                        <Form.Control type="number" defaultValue={config.alertMinimum} onChange={ev => {
                            setValue('alertMinimum', parseFloat(ev.target.value));
                        }} />
                    </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm={6}>Maximum</Form.Label>
                    <Col sm={6}>
                        <Form.Control type="number" defaultValue={config.alertMaximum} onChange={ev => {
                            setValue('alertMaximum', parseFloat(ev.target.value));
                        }} />
                    </Col>
                </Form.Group>

                <Form.Group className="mb-3">
                    <Form.Label><b>Sensoren:</b></Form.Label>
                    <div className="overflow-auto" style={{maxHeight: 150}}>
                        {config.sensors && config.sensors.map((s, i) =>
                            <div className="d-flex justify-content-between">
                                <div>
                                    <InputGroup className="mb-3">
                                        <FormControl
                                            type="text"
                                            placeholder={`${getDsById(s.datasource).name} / ${getSensorById(s.datasource, s.sensor).name}`}
                                            title={`${getDsById(s.datasource).name} / ${getSensorById(s.datasource, s.sensor).name}`}
                                            defaultValue={config.sensors[i].name}
                                            onChange={ev => {
                                                if (ev.target.value) {
                                                    config.sensors[i].name = ev.target.value;
                                                } else {
                                                    config.sensors[i].name = `${getDsById(s.datasource).name} / ${getSensorById(s.datasource, s.sensor).name}`
                                                }
                                                setValue('sensors', config.sensors)
                                            }}
                                        />
                                        <FormControl type="color" defaultValue={s.color ? s.color : getStrokeColor(i)} onChange={ev => {
                                            config.sensors[i].color = ev.target.value;
                                            setValue('sensors', config.sensors)
                                        }
                                        } />

                                        <RangeSlider min={1} max={5} step={1} defaultValue={s.width ? s.width : 1} size="sm" tooltip="off"
                                                     onChange={ev => {
                                                         config.sensors[i].width = parseInt(ev.target.value);
                                                         setValue('sensors', config.sensors)
                                                     }}/>

                                    </InputGroup>
                                </div>
                                <div><Button className="btn btn-sm btn-danger" onClick={() => {
                                    config.sensors.splice(i, 1);
                                    setValue('sensors', config.sensors)
                                }}>löschen</Button></div>
                            </div>
                        )}
                    </div>
                </Form.Group>

                <Form.Group>
                    <Dropdown>
                        <Dropdown.Toggle variant="primary"><FontAwesomeIcon icon={faPlus} /> Sensor hinzufügen</Dropdown.Toggle>
                        <Dropdown.Menu>
                            {datasources.map((ds,i) => (
                                ds.node.sensors.edges.length >= 1 && <DropdownSubmenu title={ds.node.name}>
                                    {ds.node.sensors.edges.map((s,j) => (
                                        <Dropdown.Item key={`${ds.node.pk}_${s.node.pk}_${i}_${j}`} onClick={() => {
                                            config.sensors.push({
                                                datasource: ds.node.pk,
                                                sensor: s.node.pk,
                                                name: `${getDsById(ds.node.pk).name} / ${getSensorById(ds.node.pk, s.node.pk).name}`,
                                                unit: s.unit,
                                                color: getStrokeColor(config.sensors.length)
                                            });
                                            setValue('sensors', config.sensors)
                                        }}>{s.node.name}</Dropdown.Item>
                                    ))}
                                </DropdownSubmenu>

                            ))}
                        </Dropdown.Menu>
                    </Dropdown>
                </Form.Group>
            </Form>
            <hr />
            <div className="lead">Vorschau:</div>
            { config.sensors && config.sensors.length > 0 &&
                <Chart {...config} />
            }
        </div>
    )
}
