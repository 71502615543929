import React, {Fragment} from "react";
import {ProgressBar} from "react-bootstrap";

import Marker from "assets/marker.png";

export default function ValueMeter({value, segments=[20, 60, 20], colors=['#f00', '#ff0', '#0f0', '#ff0', '#f00'], disabled=false}) {
    let rest = segments.reduce((sum, a) => sum + a, 0)

    const getValue = (segment) => {
        const v = segment > rest ? rest : segment
        rest -= v
        return v
    }

    const newSegments = [5, ...segments, 5]
    rest += 10
    const newValue = (value + 5) * .9
    const markerPosition = newValue > 100 ? 100 : newValue < 0 ? 0 : newValue

    return (
        <div className="w-100 m-0 p-0 position-relative">

                <ProgressBar className="border-1 border border-dark position-relative">
                    {!disabled ?

                            newSegments.map((s, i) => (
                                <ProgressBar style={{backgroundColor: colors[i]}} now={getValue(s)} key={i}/>
                            ))

                        : <ProgressBar style={{backgroundColor: '#dadada'}} now={100} />
                    }
                </ProgressBar>
            {!disabled && <div className="position-absolute" style={{left: `${markerPosition}%`}}>
            <div><img className="position-absolute" src={Marker} style={{top: -23, left: -5}} /></div>
            </div> }

        </div>
    )
}
