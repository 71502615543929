import {Button, Modal} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {toast} from "react-hot-toast";
import ComponentStore from "../../utils/ComponentStore";
import {useMutation} from "@apollo/client";
import {ADD_ITEM, UPDATE_ITEM} from "../../data/mutations/dashboard";

export default function ItemConfigDialog({item, onClose=() => {}, create=false}) {
    const [updateItemConfig] = useMutation(UPDATE_ITEM)
    const [createItem] = useMutation(ADD_ITEM)
    const [configGui, setConfigGui] = useState(<div>Keine Einstellungen</div>)
    const [newConfig, setNewConfig] = useState(item.config)

    const onConfigChange = (config) => {
        setNewConfig(config)
    }

    useEffect(() => {
        if (item)
            setConfigGui(ComponentStore.createConfigComponent(item.name, item.config, onConfigChange))
    }, [item])


    const saveNewConfig = () => {
        const promise = create
            ? createItem({
                variables: {
                    dashboard: item.dashboard,
                    parent: item.parent,
                    position: item.position,
                    name: item.name,
                    config: JSON.stringify(newConfig),
                }
            })
            : updateItemConfig({
                variables: {
                    id: item.id,
                    name: item.name,
                    config: JSON.stringify(newConfig),
                }
            })
        toast.promise(
            promise,
            {
                loading: "Einstellungen werden gespeichert.",
                success: data => {
                    onClose()
                    document.dispatchEvent(new Event('dashboard.reload'))
                    return `Erfolgreich gespeichert.`
                },
                error: err => {
                    if (err.networkError) {
                        console.error(item)
                        console.error(err)
                        err.networkError.result.errors.forEach(e => console.error(e.message))
                    }
                    return `Fehler: ${err.message}`
                }
            })
    }


    return (
        <Modal show={true} onHide={onClose}>
            <Modal.Header closeButton>
                <Modal.Title>{item.name} Konfiguration</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {configGui}
            </Modal.Body>
            <Modal.Footer className="d-flex justify-content-between">
                <Button variant="secondary" onClick={onClose}>Abbrechen</Button>
                <Button variant="primary" onClick={saveNewConfig}>Speichern</Button>
            </Modal.Footer>
        </Modal>
    )
}
