import {gql} from "@apollo/client";

export const ADD_DASHBOARD = gql`
    mutation AddDashboard($name: String!, $config: JSONString, $description: String, $priority: Int) {
        addDashboard(input: {
            name: $name
            description: $description
            config: $config
            priority: $priority
        }) {
            dashboard {
                key
                name
                description
                priority
                config
            }
        }
    }
`

export const SAVE_DASHBOARD = gql`
    mutation saveDashboard($id: ID!, $name: String!, $description: String!, $priority: Int!) {
        updateDashboard(id: $id, input: {
            name: $name,
            description: $description,
            priority: $priority
        }) {
            dashboard {
                id
                name
                description
                priority
            }
        }
    }
`

export const DELETE_DASHBOARD = gql`
    mutation deleteDashboard($id: ID!) {
        deleteDashboard(id: $id) {
            dashboard {
                id
            }
        }
    }
`

export const ADD_ITEM = gql`
    mutation AddItem($dashboard: ID!, $position: Int!, $parent: ID, $name: String!, $config: JSONString) {
        addItem(input: {
            dashboard: $dashboard
            parent: $parent
            position: $position
            name: $name
            config: $config
        }) {
            item {
                id
                name
                position
                config
            }
        }
    }
`

export const UPDATE_ITEM = gql`
    mutation UpdateItem($id: ID!, $name: String, $config: JSONString) {
        updateItem(id: $id, input: {
            name: $name, 
            config: $config
        }) {
            item {
                id
                name
                position
                config
            }
        }
    }
`

export const MOVE_ITEM = gql`
    mutation MoveItem($id: ID!, $parent: ID, $position: Int) {
        updateItem(id: $id, input: {
            parent: $parent,
            position: $position
        }) {
            item {
                id
                name
                position
                config
            }
        }
    }
`

export const DELETE_ITEM = gql`
    mutation DeleteItem($id: ID!) {
        deleteItem(id: $id) {
            found
            deletedId
        }
    }
`
