import React from "react";
import PropTypes from "prop-types";

export default function NotAvailable(props) {
    return (
        <div>
            <div className="text-muted">{props.name}: Element nicht gefunden</div>
        </div>
    );
}

NotAvailable.propTypes = {
    name: PropTypes.string
};

NotAvailable.defaultProps = {
    // ...
}
