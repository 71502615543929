import moment from "moment";

moment.locale("de");

export function dateTime(date) {
    return moment(date).format("DD.MM.YYYY HH:mm");
}

export function dateTimeSeconds(date) {
    return moment(date).format("DD.MM.YYYY HH:mm:ss");
}

export function time(date) {
    return moment(date).format("HH:mm");
}

export function dateMiddle(date) {
    return moment(date).format("D. MMM YYYY");
}

export function dateLong(date) {
    return moment(date).format("D. MMMM YYYY");
}
