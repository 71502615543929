import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './pages/App';
import reportWebVitals from './utils/reportWebVitals';
import {BrowserRouter} from "react-router-dom";

import { ApolloProvider } from "@apollo/client";
import {client, login, refreshAuthToken} from "./utils/Client";
import "components/dashboard/Modules";
import {Toaster} from "react-hot-toast";

import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

Sentry.init({
    dsn: "https://69fd40b2843147c2b0c6a12689a3223c@sentry.kube.busta.at/6",
    integrations: [new Integrations.BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
});

setInterval(refreshAuthToken, 1000 * 60)

ReactDOM.render(
    <BrowserRouter>
        <ApolloProvider client={client}>
          <React.StrictMode>
                <App />
                  <Toaster
                      position="bottom-right"
                      reverseOrder={false}
                  />
          </React.StrictMode>
        </ApolloProvider>
    </BrowserRouter>
    ,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
