import React, {useEffect, useState, Fragment} from "react";
import {Alert, Button, Nav, Spinner, Dropdown} from "react-bootstrap";
import {Link} from "react-router-dom";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {GET_DASHBOARDS} from "data/queries/dashboard";
import {useQuery} from "@apollo/client";
import {client} from "utils/Client";
import {useParams, useLocation} from "react-router";
import NewDashboardDialog from "./NewDashboardDialog";

export default function DashboardMenu({createDashboardDialog=() => {}}) {
    const key = useLocation().pathname.split('/').pop()
    const [showCreate, setShowCreate] = useState(false)
    const {data, loading, error, refetch} = useQuery(GET_DASHBOARDS, {
        client,
        pollInterval: 500
    })

    const createItem = () => {
        setShowCreate(true)
    }

    const hideShowCreate = () => {
        setShowCreate(false)
    }

    if (loading) return <div className="text-center"><Spinner animation="border" /></div>
    if (error) {
        console.error(error)
        return <Alert variant="danger">Fehler beim Laden</Alert>
    }


    if (!data.dashboards) return <Spinner animation="border" />
    const dashboards = data.dashboards.edges.length > 0 ? data.dashboards.edges.map(e => e.node) : []

    return (
        <Fragment>
                {dashboards.map(dashboard =>
                    <Dropdown.Item key={dashboard.key}  className={`${dashboard.key === key && "active"}`}>
                        <Nav.Link as={Link} to={`/dashboard/${dashboard.key}`}  className={`link ${dashboard.key === key && "active"}`} style={{color: `${dashboard.key === key ? '#fff' : '#000'}`}}>
                            <div>{dashboard.name || dashboard.key}</div>
                            <div className="small">{dashboard.description}</div>
                        </Nav.Link>
                    </Dropdown.Item>
                )}
                {dashboards.length < 1 && <Nav.Item className="text-center"><i className="text-muted small">Keine Dashboards gefunden</i></Nav.Item>}
                <Dropdown.Divider />
                <div className="d-grid mx-auto mb-0 pb-0">
                    <Button className="btn-block" variant="outline-primary" onClick={createItem}><FontAwesomeIcon icon={faPlus} /></Button>
                </div>
            {showCreate && <NewDashboardDialog onClose={hideShowCreate} />}
        </Fragment>
    )
}
