import {useMutation, useQuery} from "@apollo/client";
import {GET_DASHBOARD_BY_KEY} from "../data/queries/dashboard";
import {Alert, Button, Form, Modal, Spinner} from "react-bootstrap";
import {useParams} from "react-router-dom";
import React, {Fragment, useEffect, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPencilAlt, faSave, faTrash, faUndo} from "@fortawesome/free-solid-svg-icons";
import AddItemButton from "./dashboard/AddItemButton";
import DashboardItem from "./dashboard/DashboardItem";
import {DELETE_DASHBOARD, SAVE_DASHBOARD} from "../data/mutations/dashboard";
import {toast} from "react-hot-toast";
import {useHistory} from "react-router";
import {USER_INFO} from "../data/queries/authentication";

export default function Dashboard(props) {
    const {key} = useParams();
    const [dashboard, setDashboard] = useState(null)
    const [items, setItems] = useState([])
    const [editable, setEditable] = useState(true)
    const [editMode, setEditMode] = useState(false)
    const [name, setName] = useState()
    const [description, setDescription] = useState()
    const [priority, setPriority] = useState()
    const [showDeleteDashboard, setShowDeleteDashboard] = useState(false)
    const history = useHistory()
    const [userInfo, setUserInfo] = useState(null)


    const {data, loading, error, refetch} = useQuery(GET_DASHBOARD_BY_KEY, {
        variables: {key: props.key || key}
    })

    const auth = useQuery(USER_INFO)

    const [updateDashboard] = useMutation(SAVE_DASHBOARD)
    const [deleteDashboard] = useMutation(DELETE_DASHBOARD)

    useEffect(() => {
        setUserInfo(auth.data ? auth.data.me : null)
    }, [auth.data])

    useEffect(() => {
        if (data && data.dashboardByKey) {
            setDashboard(data.dashboardByKey)
            setName(data.dashboardByKey.name)
            setDescription(data.dashboardByKey.description)
            setPriority(data.dashboardByKey.priority)
            setItems(data.dashboardByKey.items.edges.length > 0 ? data.dashboardByKey.items.edges.filter(e => !Boolean(e.node.parent)).map(e => e.node) : [])
        } else {
            setDashboard(null)
            setItems([])
        }
    }, [data])

    useEffect(() => {
        setEditable(userInfo !== null)
    }, [userInfo])

    useEffect(() => {
        const eventName = `dashboard.reload`
        document.addEventListener(eventName, reloadDashboard)

        return () => document.removeEventListener(eventName, reloadDashboard)
    })

    const reloadDashboard = () => {
        refetch()
    }

    const deleteDashboardConfirm = () => {
        toast.promise(
            deleteDashboard({
                variables: {
                    id: dashboard.id
                }
            }),
            {
                loading: "Dashboard wird gelöscht.",
                success: data => {
                    setShowDeleteDashboard(false)
                    history.push('/dashboard')
                    return "Dashboard wurde gelöscht."
                },
                error: err => {
                    console.error(err)
                    return `Fehler: ${err.message}`
                }
            }
        )
    }

    const saveDashboard = () => {
        toast.promise(
            updateDashboard({
                variables: {
                    id: dashboard.id,
                    name,
                    description,
                    priority
                }
            }),
            {
                loading: "Dashboard wird gespeichert.",
                success: data => {
                    setEditMode(false)
                    return "Dashboard gespeichert."
                },
                error: err => `Fehler: ${err.message}`
            }
        )
    }

    const renderItems = () => (
        <div>
            {items.map((item, i) => (
                <Fragment>
                    {() => (
                        <div key={`${item.name}_${i}`} className="p-2">
                            <DashboardItem itemId={item.id}
                                           dashboard={dashboard}
                                           editMode={editMode}>
                            </DashboardItem>

                            {editMode &&
                                <AddItemButton dashboard={dashboard.id} position={item.position + 1}
                                               parent={null}/>}
                        </div>
                    )}

                </Fragment>
            ))}
        </div>
    )

    if (loading) return <Spinner animation="border" />
    if (error) {
        console.error(error.networkError.result)
        return <Alert variant="danger">{error.message}</Alert>
    }

    if (!dashboard) return <Alert variant="danger">Dashboard nicht gefunden</Alert>

    return (
        <Fragment>
            <div className="d-flex justify-content-between">
                <div>
                    <h2>{dashboard.name || dashboard.key}</h2>
                    <div className="lead text-muted">{dashboard.description}</div>
                </div>
                <div>
                    {editable &&
                        (
                            editMode ?
                                <div className="d-flex justify-content-end gap-2">
                                    <Button title="Dashboard löschen" onClick={() => setShowDeleteDashboard(true)} className=""
                                            variant="danger"><FontAwesomeIcon icon={faTrash}/></Button>
                                    <Button title="Abbrechen" variant="secondary" onClick={() => setEditMode(false)}><FontAwesomeIcon
                                        icon={faUndo}/></Button>
                                    <Button title="Speichern" onClick={saveDashboard}><FontAwesomeIcon
                                        icon={faSave}/></Button>
                                    <Modal show={showDeleteDashboard}>
                                        <Modal.Header>
                                            <Modal.Title>Dashboard löschen</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <div>Dashboard "{dashboard.name}" wirklich löschen?</div>
                                        </Modal.Body>
                                        <Modal.Footer className="d-flex justify-content-between">
                                            <Button variant="secondary" onClick={() => setShowDeleteDashboard(false)}>Nein</Button>
                                            <Button variant="danger" onClick={deleteDashboardConfirm}>Ja</Button>
                                        </Modal.Footer>
                                    </Modal>
                                </div>
                                :
                                <Fragment>
                                    <Button onClick={() => setEditMode(true)}><FontAwesomeIcon
                                        icon={faPencilAlt}/></Button>
                                </Fragment>

                        )
                    }
                </div>
            </div>
            <hr/>
            {editMode &&
                <div className="bg-light border p-2 m-2">
                    <div className="lead">Details:</div>
                    <div className="d-flex justify-content-start">
                        <Form.Group className="me-3">
                            <Form.Label>Name:</Form.Label>
                            <Form.Control type="text" value={name} placeholder="Titel"
                                          onChange={ev => setName(ev.target.value)}/>
                        </Form.Group>
                        <Form.Group className="me-3">
                            <Form.Label>Beschreibung:</Form.Label>
                            <Form.Control as="textarea" value={description} placeholder="Beschreibung"
                                          rows={1} onChange={ev => setDescription(ev.target.value)}/>
                        </Form.Group>
                        <Form.Group className="me-3">
                            <Form.Label>Priorität:</Form.Label>
                            <Form.Control type="number" value={priority} placeholder="Priorität"
                                          onChange={ev => setPriority(parseInt(ev.target.value))}/>
                        </Form.Group>
                    </div>
                    <hr/>
                </div>
            }
            {editMode &&
                <AddItemButton dashboard={dashboard.id} position={0} parent={null} />}
                    <div>
                        {items.map((item, i) => (
                                <div key={`${item.name}_${i}`} className="p-2">
                                    <DashboardItem itemId={item.id}
                                                   dashboard={dashboard}
                                                   editMode={editMode}>
                                    </DashboardItem>

                                    {editMode &&
                                        <AddItemButton dashboard={dashboard.id} position={item.position + 1}
                                                       parent={null}/>}
                                </div>
                        ))}
                    </div>
            {items.length === 0 && !editMode && <div className="text-muted text-center">
                <div className="lead">Dieses Dashboard beinhaltet noch keine Elemente.</div>
                <div>Wählen Sie "Bearbeiten" und fügen Sie ein paar Elemente hinzu.</div>
            </div>}

        </Fragment>
    )
}
