import {useEffect, useState} from "react";

import JMuxer from 'jmuxer';
import Hls from "hls.js";

export const supportsMSE = Boolean(window.MediaSource)

// export const useVideoPlayer = supportsMSE ? useVideoPlayerMSE : useVideoPlayerHLS
export const useVideoPlayer = useVideoPlayerHLS

export function useVideoPlayerHLS({url, ref, useVideo=true, useAudio=false, debug=false, disablePause=true, flushingTime=0, clearBuffer=true, reconnect=1000}) {
    console.debug("Using HLS Video")
    const [error, setError] = useState(null)

    const restartPlayback = () => {
        const video = ref.current
        const playVideo = () => {
            console.error("Playing video")
            video.play()
        }
        video.removeEventListener('loadmetadata', playVideo)

        if (Hls.isSupported()) {
            console.debug("Using Hls.js for playback")
            const hls = new Hls()
            hls.loadSource(url)
            hls.attachMedia(video)
            hls.on(Hls.Events.MANIFEST_PARSED, playVideo)
        } else if (video && video.canPlayType('application/vnd.apple.mpegurl')) {
            console.log("Using Safari native HLS for playback")
            video.src = url
            video.addEventListener('loadmetadata', playVideo)
        }
        return () => {
            video.removeEventListener('loadmetadata', playVideo)
        }
    }

    useEffect(() => {
        return restartPlayback()
    }, [url, ref])

    return {
        error,
        restartPlayback
    }
}

export function useVideoPlayerMSE({url, ref, useVideo=true, useAudio=false, debug=false, disablePause=true, flushingTime=0, clearBuffer=true, reconnect=1000}) {
    console.debug("Using MSE Video")
    const [jmuxer, setJmuxer] = useState(null)

    const [ws, setWs] = useState(null)

    const [error, setError] = useState(null)

    const isReset = !!(jmuxer && jmuxer.isReset)

    useEffect(() => {
        if (ref.current && (!jmuxer)) {
            try {
                setJmuxer(new JMuxer({
                    node: ref.current,
                    debug,
                    mode: 'video',
                    flushingTime,
                    clearBuffer,
                    //fps: 25,
                }))
            } catch(e) {
                console.error(e)
                setError(e)
            }
        }
    }, [url, ref, jmuxer])

    useEffect(() => {
        // const interval = setInterval(() => ref.current.currentTime = Number.MAX_SAFE_INTEGER, 1000);

        //return () => clearInterval(interval)
    }, [ref])

    useEffect(() => {
        const feed = event => jmuxer.feed(parseData(event.data))
        const close = event => {
            if (reconnect > 0) {
                console.log("connection closed. Attempting reconnect")
                setTimeout(connect, reconnect)
            }
        }
        const connect = () => {
            const ws = new WebSocket(url)
            setWs(ws)
            document.ws = ws
            ws.binaryType = 'arraybuffer'
            ws.addEventListener('message', feed)
            ws.addEventListener("close", close)
            return ws
        }
        if (jmuxer) {
            const ws = connect()
            return () => {
                ws.removeEventListener("message", feed)
                ws.removeEventListener("close", close)
                console.log("closing websocket connection")
                ws.close()
            }
        }
    }, [jmuxer, url])

    document.jmuxer = jmuxer;

    useEffect(() => {
        const toggleFullscreen = ev => {
            console.log( ev, ev.target, document.fullscreen)
            document.fullscreen ? document.exitFullscreen() : ev.target.requestFullscreen()
        }
        if (ref && ref.current) {
            //ref.current.controls = false;
            if (disablePause) {
                //ref.current.addEventListener("click", toggleFullscreen)
                ref.current.addEventListener("pause", continuePlayback)
            }

            return () => {
                //ref.current.removeEventListener("click", toggleFullscreen)
                ref.current.removeEventListener("pause", continuePlayback)
            }
        }
    }, [ref, disablePause])

    const continuePlayback = (ev) => {
        ev.target.play()
    }

    const parseData = data => {
        const input = new Uint8Array(data)
        /*
        const dv = new DataView(input.buffer)
        const audioLength = dv.getUint16(2, true);
        const duration = dv.getUint16(0)
        const audio = input.subarray(2, (audioLength + 4))
         */
        const video = input

        return {
            video,
            //audio,
            // duration
        }
    }

    return {
        jmuxer,
        error,
    }
}
