import {gql} from "@apollo/client";


export const GET_SENSOR_VALUES = gql`
    query GetLastSensorValue($sensor: ID!, $last: Int) {
        sensor(id: $sensor) {
            name
            key
            config
            datasource {
                name
            }
            unit {
                name
                si
            }
            sensorvalues(last: $last) {
                edges {
                    node {
                        time
                        value
                    }
                }
            }
        }
    }
`

export const GET_SENSOR_VALUES_BY_PK = gql`
    query GetLastSensorValueByPK($sensor: Int!, $last: Int) {
        sensorByPk(pk: $sensor) {
            name
            key
            config
            datasource {
                name
            }
            unit {
                name
                si
            }
            sensorvalues(last: $last) {
                edges {
                    node {
                        time
                        value
                    }
                }
            }
        }
    }
`

export const GET_MULTIPLE_SENSOR_VALUES_BY_PK = gql`
    query GetLastSensorValueByPK($sensors: [Int]!, $last: Int, $since: Float) {
        sensorListByPk(pk: $sensors) {
            name
            key
            config
            pk
            datasource {
                name
            }
            unit {
                name
                si
            }
            sensorvalues(last: $last, since: $since) {
                edges {
                    node {
                        time
                        value
                    }
                }
            }
        }
    }
`

export const GET_ON_OFF_VALUES = gql`
    query GetOnOffValues($sensors: [Int]!, $dateFrom: DateTime!, $dateTo: DateTime!, $threshold: Float!) {
        onOffValues(sensors: $sensors, dateFrom: $dateFrom, dateTo: $dateTo, threshold: $threshold) {
            time
            value
        }
    }
`

export const GET_AGGREGATED_SENSOR_VALUES = gql`
    query GetSensorValues($sensors: [Int]!, $dateFrom: DateTime!, $dateTo: DateTime, $datapoints: Int, $aggregateFunction: String) {
        sensorValues(sensors: $sensors, dateFrom: $dateFrom, dateTo: $dateTo, datapoints: $datapoints, aggregateFunction: $aggregateFunction) {
            sensor {
                name
                key
                pk
                unit {
                    name
                }
            }
            time
            value
        }
    }
`
