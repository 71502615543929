import {Container} from "react-bootstrap";
import LoginForm from "../components/LoginForm";

export default function LoginPage(props) {
    return (
        <Container className="p-4">
            <h1>Anmeldung</h1>
            <LoginForm />
        </Container>
    )
}
