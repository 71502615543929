import {gql} from "@apollo/client";

export const LOGIN = gql`
    mutation Login($username: String!, $password: String!) {
        tokenAuth(username: $username, password: $password) {
            success,
            errors,
            unarchiving,
            token,
            refreshToken,
            user {
                id,
                username,
                firstName,
                lastName,
            }
        }
    }
`

export const REFRESH_AUTH = gql`
    mutation RefreshAuth($refreshToken: String!) {
        refreshAuth(refreshToken: $refreshToken) {
            token
            payload
            success
            errors
            refreshToken
        }
    }
`
