import {Col, Form, Row} from "react-bootstrap";

import {useConfig} from "../../../../utils/hooks";

export default function SensorRowConfig(props) {
    const {config, setValue} = useConfig(props.config,{
        spacing: 2,
        width: 1,
        height: 1,
        vertical: false,
        fill: false,
        wrap: true,
    }, props.onConfigChange)

    return (
        <Form>
            <Form.Group className="mb-3" controlId="vertical-check">
                <Form.Check type="checkbox" label="Vertikal ausrichten" checked={config.vertical} onChange={ev => setValue('vertical', ev.target.checked) }/>
            </Form.Group>
            <Form.Group className="mb-3" controlId="expand-check">
                <Form.Check type="checkbox" label="Elemente gleichmäßig expandieren" checked={config.fill} onChange={ev => {
                    setValue('fill', ev.target.checked);
                }} />
            </Form.Group>
            <Form.Group className="mb-3" controlId="wrap-check">
                <Form.Check type="checkbox" label="Zeilenumbruch erlauben" checked={config.wrap} onChange={ev => {
                    setValue('wrap', ev.target.checked);
                }} />
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
                <Form.Label column sm={6}>Abstand zwischen Elementen</Form.Label>
                <Col sm={6}>
                    <Form.Control type="number" aria-valuemin={0} aria-valuemax={5} min={0} max={5} placeholder="Abstand" value={config.spacing} onChange={(ev) => setValue('spacing', ev.target.value) } />
                </Col>
            </Form.Group>
        </Form>
    )
}
