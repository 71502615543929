import React from "react";
import PropTypes from "prop-types";

export default function Preview(props) {
    return (
        <div className="border border-1 p-2 w-100 h-100">
            <div className="lead">Vorschau:</div>
            {props.children}
        </div>
    );
}

Preview.propTypes = {
    // ...
};

Preview.defaultProps = {
    // ...
}
