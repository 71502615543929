import {Form, Button, Alert, Spinner, FloatingLabel} from "react-bootstrap";
import {useMemo, useState} from "react";
import {login, logout} from "../utils/Client";
import {useQuery} from "@apollo/client";
import {USER_INFO} from "../data/queries/authentication";
import {useHistory, useLocation} from "react-router";

export default function LoginForm(props) {
    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")
    const [errors, setErrors] = useState([])
    const [success, setSuccess] = useState(undefined)
    const {data, loading, error, refetch} = useQuery(USER_INFO)
    const history = useHistory()
    const {search} = useLocation()

    const searchParams = useMemo(() => new URLSearchParams(search), [search])

    const next = searchParams.get('next')

    const handleLogin = ev => {
        ev.preventDefault()
        login(username, password).then(result => {
            if (!result || !result.success) {
                setErrors(result ? result.errors.nonFieldErrors : "")
                setSuccess(false)
            } else {
                setErrors([])
                setSuccess(true)
                refetch()
                if (next) {
                    history.push(next)
                }
                if (props.onLogin) props.onLogin()

            }
            console.log(result)
        })
    }

    const handleLogout = ev => {
        logout()
        setSuccess(undefined)
        refetch()
    }

    const renderErrors = () => {
        if (errors.length > 0) {
            return (
                <div>
                    {errors.map(e => (
                        <Alert variant="danger" key={e.code}>{e.message}</Alert>
                    ))}
                </div>
            )
        }
    }

    const renderSuccess = () => {
        if (success === false) {
            return <Alert variant="danger">Login fehlgeschlagen</Alert>
        } else if (success === true) {
            return <Alert variant="success">Login erfolgreich</Alert>
        }
    }

    const updateUsername = ev => {
        setUsername(ev.target.value)
    }

    const updatePassword = ev => {
        setPassword(ev.target.value)
    }

    if (loading) return <Spinner animation="border" />

    if (data.me) {
        return <Button onClick={handleLogout}>Abmelden</Button>
    }

    return (
        <Form onSubmit={handleLogin}>
            <FloatingLabel label="Benutzername" className="mb-1">
                <Form.Control required type="text" name="username" placeholder="Benutzername" value={username} onChange={updateUsername} />
            </FloatingLabel>
            <FloatingLabel label="Password" className="mb-1">
                <Form.Control required type="password" name="password" placeholder="Passwort" value={password} onChange={updatePassword} />
            </FloatingLabel>
            <div className="d-flex justify-content-end"><Button variant="primary" type="submit">Login</Button></div>
            <div className="mt-3 mx-1">
            {renderSuccess()}
            </div>
        </Form>
    )
}
