import React, {useEffect, useState, useRef} from "react";

import {assignIfUndefined} from "./helpers";

export function useForceUpdate(){
    const [value, setValue] = useState(0); // integer state
    return () => setValue(value => value + 1); // update the state to force render
}

export function useConfig(baseConfig, defaults, onChange=() => {}) {
    const [config, setConfig] = useState({})

    useEffect(() => {
        setConfig(assignIfUndefined(baseConfig, defaults))
    }, [baseConfig])


    const setValue = (key, value) => {
        const c = config
        c[key] = value
        setConfig({...c})
        onChange(c)
        console.log("setting",key,value)
    }

    return {config, setConfig, setValue}
}

