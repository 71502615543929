import React, { useEffect, useRef, useState} from "react";
import {Spinner, Col, Form, Row} from "react-bootstrap";
import {useQuery} from "@apollo/client";

import {useConfig, useForceUpdate} from "../../../../utils/hooks";
import {GET_DATASOURCES} from "../../../../data/queries/datasources";
import {client} from "utils/Client";
import Preview from "../../Preview";
import SensorItem from "../SensorItem";

export default function SensorItemConfig(props) {
    const [datasources, setDatasources] = useState([]);
    const [currentSensorConfig, setCurrentSensorConfig] = useState({})
    const forceUpdate = useForceUpdate()
    const {config, setValue} = useConfig(props.config, {
        datasource: 0,
        sensor: 0,
        width: 1,
        height: 1,
        title: "",
        alertMinimum: null,
        alertMaximum: null,
        level1: null,
        level2: null,
        gauge: false,
        showAlert: true,
        updateInterval: 30000,
    }, props.onConfigChange)

    const sensorSelect = useRef(null)

    const {data, loading, error} = useQuery(GET_DATASOURCES, {
        client
    })

    useEffect(() => {
        const current = getSensors(parseInt(config.datasource)).find(s => s.node.pk === parseInt(config.sensor)) || {}

        if (current.node && current.node.config) {
            setCurrentSensorConfig(JSON.parse(current.node.config))
        } else {
            setCurrentSensorConfig({})
        }
    }, [config, config.sensor, config.datasource, data, loading])

    console.log("sensor config", currentSensorConfig)

    const getSensors = dsId => {
        const ds = datasources.filter(d => d.node.pk === dsId).shift()
        return ds ? ds.node.sensors.edges : []
    }

    useEffect(() => {
        if (data) {
            setDatasources(data.datasources.edges)
        }
    }, [data])

    if (error) return <div>Error</div>
    if (loading || !data) return <Spinner animation="border" />

    return (
        <div>
            <Form>
                <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm={6}>Titel</Form.Label>
                    <Col sm={6}>
                        <Form.Control value={config.title} onChange={ev => {
                            setValue('title', ev.target.value);
                        }} />
                    </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm={6}>Datenquelle</Form.Label>
                    <Col sm={6}>
                        <Form.Select value={parseInt(config.datasource)} onChange={ev => {
                            setValue('datasource', parseInt(ev.target.value));
                            sensorSelect.current.value = "";
                        }}>
                            <option value="">Bitte auswählen...</option>
                            {datasources.map(ds => (
                                ds.node.sensors.edges.length > 0 && <option value={ds.node.pk} selected={ds.node.pk === parseInt(config.datasource)}>{ds.node.name} ({ds.node.sensors.edges.length} Sensoren)</option>
                            ))}
                        </Form.Select>
                    </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm={6}>Sensor</Form.Label>
                    <Col sm={6}>
                    <Form.Select ref={sensorSelect} value={parseInt(config.sensor)} onChange={ev => {
                        setValue('sensor', parseInt(ev.target.value));
                    }}>
                        <option value="">Bitte auswählen...</option>
                        {getSensors(config.datasource).map(s => (
                            <option value={s.node.pk}>{s.node.name}</option>
                        ))}
                    </Form.Select>
                    </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm={6}>Aktualisierungsintervall (Sekunden)</Form.Label>
                    <Col sm={6}>
                        <Form.Control type="number" value={parseInt(config.updateInterval) / 1000} onChange={ev => {
                            setValue('updateInterval', parseInt(ev.target.value) * 1000);
                            forceUpdate();
                        }} />
                    </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm={6}>Kritisch Minimum</Form.Label>
                    <Col sm={6}>
                        <Form.Control type="number" value={config.alertMinimum} placeholder={currentSensorConfig.min} onChange={ev => {
                            setValue('alertMinimum', parseFloat(ev.target.value));
                            forceUpdate();
                        }} />
                    </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm={6}>Kritisch Maximum</Form.Label>
                    <Col sm={6}>
                        <Form.Control type="number" value={config.alertMaximum} placeholder={currentSensorConfig.max} onChange={ev => {
                            setValue('alertMaximum', parseFloat(ev.target.value));
                            forceUpdate();
                        }} />
                    </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm={6}>Warnung Minimum</Form.Label>
                    <Col sm={6}>
                        <Form.Control type="number" value={config.level1} placeholder={currentSensorConfig.level1} onChange={ev => {
                            setValue('level1', parseFloat(ev.target.value));
                            forceUpdate();
                        }} />
                    </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm={6}>Warnung Maximum</Form.Label>
                    <Col sm={6}>
                        <Form.Control type="number" value={config.level2} placeholder={currentSensorConfig.level2} onChange={ev => {
                            setValue('level2', parseFloat(ev.target.value));
                            forceUpdate();
                        }} />
                    </Col>
                </Form.Group>
                <Form.Group controlId="alert-check">
                    <Form.Check type="checkbox" label="Alarmieren" checked={config.showAlert} onChange={ev => setValue('showAlert', ev.target.checked) }/>
                </Form.Group>
                {/*
                <Form.Group controlId="gauge-check">
                    <Form.Check type="checkbox" label="Pegelanzeige" checked={config.gauge} onChange={ev => setValue('gauge', ev.target.checked) }/>
                </Form.Group>
                */}
            </Form>
            <hr />
            <Preview>
                <SensorItem {...config} />
            </Preview>
        </div>
    )
}
