export const strokeColors = [
    "#ff7300",
    "#3faf00",
    "#4f73f0",
    "#fa3300",
    "#f22390",
    "#0ff3a0",
    "#ffa3f0",
]

export const timeframes = [
    {value: 60*60, name: "1 Stunde"},
    {value: 60*60*12, name: "12 Stunden"},
    {value: 60*60*24, name: "1 Tag"},
    {value: 60*60*24*7, name: "1 Woche"},
    {value: 60*60*24*14, name: "2 Wochen"},
    {value: 60*60*24*30, name: "30 Tage"},
    {value: 60*60*24*365, name: "1 Jahr"},
    {value: -1, name: "Unbegrenzt"},
]
