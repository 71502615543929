import React, {useEffect, useState, Fragment, useMemo} from "react";
import PropTypes from "prop-types";
import ComponentStore from "../../../utils/ComponentStore";
import {
    Card,
    Spinner
} from "react-bootstrap";
import {
    CartesianGrid,
    Legend,
    Line,
    LineChart,
    XAxis,
    Tooltip,
    YAxis,
    ResponsiveContainer,
    ReferenceLine,
    Brush
} from "recharts";
import moment from "moment";

import {useQuery} from "@apollo/client";
import {GET_AGGREGATED_SENSOR_VALUES} from "../../../data/queries/sensors";

import {client} from "utils/Client";
import ChartConfig from "./config/ChartConfig";
import ItemWrapper from "../ItemWrapper";
import {strokeColors, timeframes} from "../../../data/consts";



export function getStrokeColor(index) {
    return strokeColors[index % strokeColors.length]
}

export default function Chart(props) {
    const dateFrom = useMemo(() => moment().subtract(props.timeframe * 2, "seconds"), [props.timeframe, props.datapoints])
    const dateTo = useMemo(() => new Date().toISOString(), [])

    const [values, setValues] = useState([])
    const [sensors, setSensors] = useState({})

    const {data, loading, error} = useQuery(GET_AGGREGATED_SENSOR_VALUES, {
        variables: {
            sensors: props.sensors.map(s => s.sensor),
            dateFrom: dateFrom,
            dateTo: dateTo,
            datapoints: props.datapoints * 2,
            aggregate_function: "avg"
        },
        pollInterval: props.pollInterval,
        client,
        onCompleted: (data) => {
            console.log("got data: ", data)
        }
    })

    const startIndex = useMemo(() => {
        if (data && data.sensorValues !== null) {
            const start = data.sensorValues.length - props.datapoints
            return start > 0 ? start : 0
        }
        return 0
    }, [data, props.datapoints])


    useEffect(() => {
        if (data && data.sensorValues !== null) {
            const results = []
            const sensorResults = {}
            data.sensorValues.forEach(s => {
                if (!(s.sensor.pk in sensorResults)) {
                    sensorResults[s.sensor.pk] = s.sensor
                }
                const point = {time: new Date(s.time).getTime() / 1000}
                point[s.sensor.pk] = s.value
                results.push(point)
            })
            setSensors(sensorResults)
            setValues(results)
        } else {
            setValues([])
            setSensors({})
        }
    }, [data])

    console.log("DATA:", data, loading, error, "sensors:", sensors, "values:", values)

    if (loading) {
        return <Spinner animation="border" />
    }

    if (error) {
        if (error.networkError) {
            error.networkError.result.errors.forEach(e => console.error(e))
        }
        console.error(error)
        return <div className="alert alert-danger">{error.message}</div>
    }

    let lastDate = null

    const renderDateTick = (tick) => {
        const date = moment.unix(tick.payload.value).format('DD.MM.YY')
        if (lastDate !== date) {
            lastDate = date
            return <text textAnchor="middle" x={tick.x} y={tick.y}
                         dy={16}>{date}</text>;
        } else {
            return false
        }
    }

    return (
        <ItemWrapper
            header={
                <Fragment>
                    <div className="text-muted small"><strong>{props.title !== undefined ? props.title : data.name}</strong></div>
                    <div className="text-muted small">{timeframes.filter(tf => tf.value === props.timeframe)[0].name}</div>
                </Fragment>
            }
        >
            <ResponsiveContainer width="100%" minWidth={250} height="100%" minHeight={200}>
                <LineChart data={values}>
                    <XAxis dataKey="time" tickFormatter={timeStr => moment.unix(timeStr).format('HH:mm')} xAxisId={0} scale="time" type={'category'} />
                    <XAxis dataKey="time" interval={0} tick={renderDateTick} xAxisId={1} scale="time" type={'category'} />
                    <YAxis unit={sensors.length > 0 ? sensors[0].unit && sensors[0].unit.name : ""} />
                    <CartesianGrid stroke="#f5f5f5" />
                    {props.sensors.map((s, i) =>
                    { const sensor = sensors[parseInt(s.sensor)]
                        const vals = values[s.sensor]
                        console.log("sensor:", s, sensor)
                        return sensor ? <Fragment key={`x_line_${sensor.pk}`} >
                            <Line type="monotone" dataKey={sensor.pk} stroke={s.color ? s.color : getStrokeColor(i)} yAxisId={0} isAnimationActive={false} animationDuration={250}
                                  name={sensor.name} connectNulls={true} unit={sensor.unit ? sensor.unit.name : ""} strokeWidth={s.width ? s.width  : 1} />
                        </Fragment> : null
                    })}
                    <ReferenceLine y={props.alertMinimum} stroke="red" strokeDasharray="3 3" />
                    <ReferenceLine y={props.alertMaximum} stroke="red" strokeDasharray="3 3" />
                    <Legend />
                    <Tooltip labelFormatter={timeStr => moment.unix(timeStr).format('DD. MM. YYYY / HH:mm')} />
                    <Brush dataKey='time' startIndex={startIndex} height={30} stroke="#8884d8" tickFormatter={t => {
                        return moment.unix(t).format('DD. MM. YY / HH:mm')
                    }} />
                </LineChart>
            </ResponsiveContainer>
        </ItemWrapper>

    )
}

Chart.propTypes = {
    updateInterval: PropTypes.number,
    sensors: PropTypes.arrayOf(PropTypes.object).isRequired,
    title: PropTypes.string,
    alertMinimum: PropTypes.number,
    alertMaximum: PropTypes.number,
    timeframe: PropTypes.number,
    datapoints: PropTypes.number,
};

Chart.defaultProps = {
    updateInterval: 1000,
    title: "",
    alertMinimum: null,
    alertMaximum: null,
    timeframe: 60*60,
    datapoints: 40,
}

ComponentStore.registerItem("Chart", Chart, "Chart", ChartConfig);
