import {gql} from "@apollo/client";


export const GET_DATASOURCES = gql`
    query GetDatasources {
        datasources {
            edges {
                node {
                    id
                    pk
                    name
                    sensors {
                        edges {
                            node {
                                id
                                pk
                                name
                                config
                                valueCount
                            }
                        }
                    }
                }
            }
        }
    }
`
