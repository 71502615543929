import {Col, Form, Row} from "react-bootstrap";

import {assignIfUndefined} from "../../../../utils/helpers";
import Preview from "../../Preview";
import TitleHeader from "../TitleHeader";
import {useConfig} from "../../../../utils/hooks";

export default function TitleHeaderConfig(props) {
    const {config, setValue} = useConfig(props.config, {
        title: "",
        subtitle: "",
        topLine: false,
        bottomLine: false,
        size: 'h3',
    }, props.onConfigChange)

    return (
        <div>
            <Form>
                <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm={3}>Titel</Form.Label>
                    <Col sm={9}>
                        <Form.Control defaultValue={config.title} onChange={(ev) => setValue('title', ev.target.value) } />
                    </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm={3}>Untertitel</Form.Label>
                    <Col sm={9}>
                        <Form.Control defaultValue={config.subtitle} onChange={(ev) => setValue('subtitle', ev.target.value) } />
                    </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm={3}>Größe</Form.Label>
                    <Col sm={9}>
                        <Form.Select onChange={ev => setValue('size', ev.target.value)}>
                            <option value="h1" selected={config.size === 'h1'}>5 (Riesig)</option>
                            <option value="h2" selected={config.size === 'h2'}>4</option>
                            <option value="h3" selected={config.size === 'h3'}>3</option>
                            <option value="h4" selected={config.size === 'h4'}>2</option>
                            <option value="h5" selected={config.size === 'h5'}>1 (Klein)</option>
                        </Form.Select>
                    </Col>
                </Form.Group>

                <Form.Group>
                    <Form.Check type="checkbox" label="Obere Linie" onChange={ev => setValue('topLine', ev.target.checked) }/>
                </Form.Group>

                <Form.Group>
                    <Form.Check type="checkbox" label="Untere Linie" onChange={ev => setValue('bottomLine', ev.target.checked) }/>
                </Form.Group>
            </Form>
            <Preview>
                <TitleHeader {...config} />
            </Preview>
        </div>
    )
}
