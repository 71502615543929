import React from "react";
import PropTypes from "prop-types";
import ComponentStore from "../../../utils/ComponentStore";

import Linkify from 'react-linkify';
import TextFieldConfig from "./config/TextFieldConfig";

export default function TextField(props) {
    return <div className="my-2" style={{whiteSpace: "pre-wrap"}}><Linkify>{props.text}</Linkify></div>;
}

TextField.propTypes = {
    text: PropTypes.string
};

TextField.defaultProps = {
    text: ""
}


ComponentStore.registerItem("TextField", TextField, "Textfeld", TextFieldConfig);
