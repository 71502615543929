import {Button, Col, Dropdown, Form, FormControl, FormSelect, InputGroup, Row} from "react-bootstrap";

import {useConfig} from "../../../../utils/hooks";
import {timeframes} from "../../../../data/consts";
import React, {useEffect, useState} from "react";
import Chart, {getStrokeColor} from "../Chart";
import RangeSlider from "react-bootstrap-range-slider";
import {useQuery} from "@apollo/client";
import {GET_DATASOURCES} from "../../../../data/queries/datasources";
import {client} from "../../../../utils/Client";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import {DropdownSubmenu} from "react-bootstrap-submenu";
import DataList from "../DataList";

export default function SensorRowConfig(props) {
    const [datasources, setDatasources] = useState([]);
    const {config, setValue} = useConfig(props.config,{
        threshold: 0,
        sensors: [],
        timeframe: timeframes[0].value,

    }, props.onConfigChange)

    const {data, loading, error} = useQuery(GET_DATASOURCES, {
        client
    })

    useEffect(() => {
        if (data) {
            setDatasources(data.datasources.edges)
        }
    }, [data])

    const getDsById = dsId => {
        const ds = datasources.filter(ds => ds.node.pk === dsId);
        if (ds.length === 1)
            return ds[0].node
        else
            return {}
    }

    const getSensorById = (dsId, sensorId) => {
        const ds = getDsById(dsId);
        if (ds.sensors && ds.sensors.edges.length > 0) {
            const sensor = ds.sensors.edges.filter(s => s.node.pk === sensorId);
            if (sensor.length === 1)
                return sensor[0].node
        }
        return {}
    }

    return (
        <div>
            <Form>
                <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm={6}>Zeitraum</Form.Label>
                    <Col sm={6}>
                        <FormSelect onChange={ev => {
                            setValue('timeframe', parseInt(ev.target.value));
                        }}>
                            {timeframes.map(tf =>
                                <option selected={tf.value === config.timeframe} value={tf.value}>{tf.name}</option>
                            )}
                        </FormSelect>
                    </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm={6}>Schwellwert</Form.Label>
                    <Col sm={6}>
                        <Form.Control type="number" aria-valuemin={0} aria-valuemax={5} min={0} max={5} placeholder="Schwellwert" value={config.threshold} onChange={(ev) => setValue('threshold', ev.target.value) } />
                    </Col>
                </Form.Group>

                <Form.Group className="mb-3">
                    <Form.Label><b>Sensoren:</b></Form.Label>
                    <div className="overflow-auto" style={{maxHeight: 150}}>
                        {config.sensors && config.sensors.map((s, i) =>
                            <div className="d-flex justify-content-between">
                                <div>
                                    <ul>
                                        <li>{s.name}</li>
                                    </ul>
                                </div>
                                <div><Button className="btn btn-sm btn-danger" onClick={() => {
                                    config.sensors.splice(i, 1);
                                    setValue('sensors', config.sensors)
                                }}>löschen</Button></div>
                            </div>
                        )}
                    </div>
                </Form.Group>
                <Form.Group>
                    <Dropdown>
                        <Dropdown.Toggle variant="primary"><FontAwesomeIcon icon={faPlus} /> Sensor hinzufügen</Dropdown.Toggle>
                        <Dropdown.Menu>
                            {datasources.map((ds,i) => (
                                ds.node.sensors.edges.length >= 1 && <DropdownSubmenu title={ds.node.name}>
                                    {ds.node.sensors.edges.map((s,j) => (
                                        <Dropdown.Item key={`${ds.node.pk}_${s.node.pk}_${i}_${j}`} onClick={() => {
                                            config.sensors.push({
                                                datasource: ds.node.pk,
                                                sensor: s.node.pk,
                                                name: `${getDsById(ds.node.pk).name} / ${getSensorById(ds.node.pk, s.node.pk).name}`,
                                                unit: s.unit,
                                                color: getStrokeColor(config.sensors.length)
                                            });
                                            setValue('sensors', config.sensors)
                                        }}>{s.node.name}</Dropdown.Item>
                                    ))}
                                </DropdownSubmenu>

                            ))}
                        </Dropdown.Menu>
                    </Dropdown>
                </Form.Group>
            </Form>
            <hr />
            <div className="lead">Vorschau:</div>
            { config.sensors && config.sensors.length > 0 &&
                <DataList {...config} />
            }
        </div>
    )
}
