import React from "react";

export default function ItemWrapper({header, footer, children, className=""}) {
    return (
        <div className={`${className} d-flex h-100 flex-column item-wrapper border border-1 rounded`}>
            <div className="d-flex justify-content-between p-1 px-2 m-0 item-header border-bottom border-1 p-1 bg-light">
                {header}
            </div>
            <div className="p-0 m-0 flex-grow-1 item-body d-flex">
                {children}
            </div>
            {!!footer &&
                <div
                    className="small text-muted d-flex justify-content-between p-0 pr-2 align-items-center item-footer p-1 bg-light border-top border-1">
                    {footer}
                </div>
            }
        </div>
    )
}
