import React, {Fragment, useEffect, useState} from "react";
import PropTypes from "prop-types";
import {dateTime} from "utils/format";
import ComponentStore from "../../../utils/ComponentStore";

import * as Icon from 'react-bootstrap-icons';

import {useQuery} from "@apollo/client";
import {GET_SENSOR_VALUES_BY_PK} from "../../../data/queries/sensors";
import {Modal, Spinner, Tab, Tabs} from "react-bootstrap";
import {client} from "utils/Client";
import SensorItemConfig from "./config/SensorItemConfig";
import ItemWrapper from "../ItemWrapper";



import GaugeChart from 'react-gauge-chart'
import ValueMeter from "../../valuemeter";
import Chart from "./Chart";
import {timeframes} from "../../../data/consts";


export default function SensorItem(props) {
    const {data, loading, error} = useQuery(GET_SENSOR_VALUES_BY_PK, {
        client,
        variables: {
            last: 2,
            sensor: props.sensor
        },
        pollInterval: props.updateInterval != null ? props.updateInterval : 10000,
    })

    const [alert, setAlert] = useState('')
    const [sensor, setSensor] = useState(null)
    const [sensorConfig, setSensorConfig] = useState({})
    const [current, setCurrent] = useState({})
    const [last, setLast] = useState({})
    const [change, setChange] = useState(0)
    const [showCharts, setShowCharts] = useState(false)

    const levels = {
        min: props.alertMinimum || sensorConfig.min || null,
        max: props.alertMaximum || sensorConfig.max || null,
        level1: props.level1 || sensorConfig.level1 || null,
        level2: props.level2 || sensorConfig.level2 || null,
    }

    const oldValueThreshold = 3600;

    const updateAlert = (value) => {
        if (!props.showAlert) {
            setAlert("")
        } else {
            let alert = "value-ok"
            if (value == null) alert = "value-unknown"
            if (levels.level1 != null && value < levels.level1) alert = "value-warning"
            if (levels.level2 != null && value > levels.level2) alert = "value-warning"
            if (levels.min != null && value < levels.min) alert = 'value-critical'
            if (levels.max != null && value > levels.max) alert = 'value-critical'
            console.log("alert", alert)
            setAlert(alert)
        }
    }

    useEffect(() => {
        if (data && data.sensorByPk) {
            const sensor = data.sensorByPk
            const values = sensor.sensorvalues.edges.map(e => e.node).reverse();
            const current = values.length > 0 ? values[0] : {}
            const last = values.length > 1 ? values[1] : {}
            const change = current.value - last.value
            setSensor(sensor)
            setSensorConfig(JSON.parse(sensor.config) || {})
            setCurrent(current)
            setLast(last)
            setChange(change)
        }
    }, [data])

    useEffect(() => {
        updateAlert(current.value)
    }, [props, current])

    const renderGauge = (value) => {
        const percent = (value - levels.min) / (levels.max - levels.min)
        const step = (props.warningLevel || 0) / (props.alertMaximum - props.alertMinimum)
        console.log("step", step)
        return (
            <GaugeChart
                nrOfLevels={3}
                percent={percent}
                arcsLength={[step, (1 - (step*2)), step]}
                arcPadding={0}
                colors={['#ffc107', '#198754', '#ffc107']}
                textColor='black'
                formatTextValue={() => `${value} ${sensor.unit ? sensor.unit.name : ""}`}
            />
        )
    }

    const getAge = () => {
        const now = Date.now();
        const timeOfCurrent = Date.parse(current.time);
        return Math.ceil((now - timeOfCurrent) / 1000);
    }

    const renderValue = value => {
        const h = levels.max - levels.min
        const percent = ((value - levels.min) / h) * 100
        const warnPercent1 = ((levels.level1 - levels.min) / h) * 100
        const warnPercent2 = 100 - ((levels.level2 - levels.min) / h) * 100
        // const percent = ((value - levels.min) / (levels.max - levels.min)) * 100
        // const warnPercent1 = Math.abs((((levels.min + levels.level1) - levels.min) / (levels.max - levels.min)) * 100)
        // const warnPercent2 = Math.abs((((levels.max - levels.level2)) / (levels.max - levels.min)) * 100)

        console.log("percent", percent, warnPercent1, warnPercent2)

        const showMeter = percent !== Infinity && percent !== -Infinity

        return (
            <div className="d-flex flex-column h-100 justify-content-between w-100">
            <div className="h3">
                {current.value != null ? props.format(value) : "NaN"}&nbsp;<span
                className="small align-bottom">{sensor.unit ? sensor.unit.name : ""}</span>
            </div>

            <div onClick={() => setShowCharts(true)} style={{cursor: "pointer"}} role="button">
                <ValueMeter value={percent} segments={[warnPercent1, 100 - (warnPercent1+warnPercent2), warnPercent2]} disabled={!showMeter} />
            </div>

        </div>
        )
    }

    if (loading) {
        return <Spinner animation="border" />
    }

    if (error) {
        console.error(error);
        if (error.networkError) {
            error.networkError.result.errors.forEach(e => console.error(e.message));
        }
        return <div className="small alert alert-danger">{error.message}</div>
    }

    if (!sensor) {
        return <div className="small alert alert-warning">Keine Daten</div>
    }

    return (
        <Fragment>
            <ItemWrapper
                header={
                    <Fragment>
                        <div className="text-muted small">{sensor.datasource.name}</div>
                        <div className="small px-2 text-muted"><strong>|</strong></div>
                        <div className="text-muted small"><strong>{props.title ? props.title : sensor.name}</strong></div>
                    </Fragment>
                }
                footer={
                    <Fragment>
                        <div className="justify-content-center align-items-center">
                            { change > 0 ? <Icon.CaretUpFill /> : change < 0 ? <Icon.CaretDownFill /> : <Icon.ChevronExpand /> }
                            {props.format(change)}
                        </div>&nbsp;|&nbsp;
                        <div className="text-muted">{dateTime(new Date(Date.parse(current.time)))}</div>
                    </Fragment>
                }
            >
                <div
                    className={`sensor-item text-center align-items-center d-flex justify-content-center m-0 m-0 p-0 ${alert} position-relative h-auto w-100 p-2`}>
                    { getAge() > oldValueThreshold && <div className="overlay"/> }
                    {(props.gauge && props.alertMinimum != null && props.alertMaximum != null)
                        ? renderGauge(current.value)
                        : renderValue(current.value)
                    }

                </div>
            </ItemWrapper>
            {showCharts &&
                <Modal size="lg" show={showCharts} onHide={() => setShowCharts(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>{props.title || sensor.name}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Tabs defaultActiveKey="1d" className="mb-3">
                            <Tab eventKey="1h" title="1 Stunde">
                                <Chart alertMinimum={levels.min} alertMaximum={levels.max} sensors={[{
                                    sensor: props.sensor,
                                    datasource: props.datasource,
                                    name: props.title || sensor.name,
                                    color: '#008800',
                                    width: 1,
                                }]} timeframe={timeframes[0].value}/>
                            </Tab>
                            <Tab eventKey="1d" title="24 Stunden">
                                <Chart alertMinimum={levels.min} alertMaximum={levels.max} sensors={[{
                                    sensor: props.sensor,
                                    datasource: props.datasource,
                                    name: props.title || sensor.name,
                                    color: '#008800',
                                    width: 1,
                                }]} timeframe={timeframes[2].value}/>
                            </Tab>
                            <Tab eventKey="1w" title="1 Woche">
                                <Chart alertMinimum={levels.min} alertMaximum={levels.max} sensors={[{
                                    sensor: props.sensor,
                                    datasource: props.datasource,
                                    name: props.title || sensor.name,
                                    color: '#008800',
                                    width: 1,
                                }]} timeframe={timeframes[3].value}/>
                            </Tab>
                            <Tab eventKey="1m" title="1 Monat">
                                <Chart alertMinimum={levels.min} alertMaximum={levels.max} sensors={[{
                                    sensor: props.sensor,
                                    datasource: props.datasource,
                                    name: props.title || sensor.name,
                                    color: '#008800',
                                    width: 1,
                                }]} timeframe={timeframes[5].value}/>
                            </Tab>
                        </Tabs>
                    </Modal.Body>

                </Modal>
            }
        </Fragment>
    )
}

SensorItem.propTypes = {
    format: PropTypes.func,
    updateInterval: PropTypes.number,
    datasource: PropTypes.number.isRequired,
    sensor: PropTypes.number.isRequired,
    title: PropTypes.string,
    alertMinimum: PropTypes.number,
    alertMaximum: PropTypes.number,
    level1: PropTypes.number,
    level2: PropTypes.number,
}

SensorItem.defaultProps = {
    format: value => value.toFixed(1),
    updateInterval: 1000,
    title: "",
    alertMinimum: null,
    alertMaximum: null,
    level1: null,
    level2: null
}


ComponentStore.registerItem("SensorItem", SensorItem, "Sensor", SensorItemConfig);
