import Preview from "../../Preview";
import {Col, Form, Row} from "react-bootstrap";

import Camera from "../Camera";
import {useConfig} from "../../../../utils/hooks";

import {protocols} from "../Camera";
import {useEffect, useState} from "react";

export default function CameraConfig(props) {
    const {config, setValue} = useConfig(props.config,{
        title: "Video",
        address: "",
        username: "",
        password: "",
        protocol: "rtsp",
        path: "",
        port: null,

    }, props.onConfigChange)

    const [protocol, setProtocol] = useState({})

    useEffect(() => {
        if (config.protocol) {
            console.log("protocols", protocols[config.protocol])
            setProtocol(protocols[config.protocol])
        }
    }, [config.protocol])

    return (
        <div>
            <Form>
                <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm={3}>Titel</Form.Label>
                    <Col sm={9}>
                        <Form.Control defaultValue={config.title} onChange={(ev) => setValue('title', ev.target.value) } />
                    </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm={3}>Protokoll</Form.Label>
                    <Col sm={9}>
                    <Form.Select onChange={ev => {
                        config.protocol = ev.target.value;
                    }}>
                        {Object.keys(protocols).map(k =>
                            <option selected={config.protocol === k} value={k}>{k}</option>
                        )}
                    </Form.Select>
                    </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm={3}>Kamera IP</Form.Label>
                    <Col sm={9}>
                        <Form.Control defaultValue={config.address} onChange={(ev) => setValue('address', ev.target.value) } />
                    </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm={3}>Port</Form.Label>
                    <Col sm={9}>
                        <Form.Control type="number" placeholder={protocol.port} defaultValue={config.port} onChange={(ev) => setValue('port', ev.target.value) } />
                    </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm={3}>Pfad</Form.Label>
                    <Col sm={9}>
                        <Form.Control defaultValue={config.path} onChange={(ev) => setValue('path', ev.target.value) } />
                    </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm={3}>Benutzername</Form.Label>
                    <Col sm={9}>
                        <Form.Control defaultValue={config.username} onChange={(ev) => setValue('username', ev.target.value) } />
                    </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm={3}>Passwort</Form.Label>
                    <Col sm={9}>
                        <Form.Control type="password" defaultValue={config.password} onChange={(ev) => setValue('password', ev.target.value) } />
                    </Col>
                </Form.Group>
            </Form>
            <Preview>
                <div style={{height: 250}}>
                    <Camera {...config} />
                </div>
            </Preview>
        </div>
    )
}
