import DashboardMenu from "../components/dashboard/DashboardMenu";
import {Button, Spinner} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import Dashboard from "../components/Dashboard";
import NewDashboardDialog from "../components/dashboard/NewDashboardDialog";
import {useLazyQuery} from "@apollo/client";
import {GET_DASHBOARDS} from "../data/queries/dashboard";
import {useHistory} from "react-router";

export default function DashboardPage(props) {
    const {key} = useParams();
    const [showCreate, setShowCreate] = useState(false)
    const [getFirstDashboard, {loading, data}] = useLazyQuery(GET_DASHBOARDS)
    const history = useHistory()

    useEffect(() => {
        if (!key) {
            getFirstDashboard().then(data => {
                console.log("got data", data)
                const first = [...data.data.dashboards.edges].shift()
                if (first) {
                    history.push(`/dashboard/${first.node.key}`)
                }
            }).catch(error => {
                console.error(error)
            })
        }
    }, [key])

    const createItem = () => {
        setShowCreate(true)
    }

    const hideShowCreate = () => {
        setShowCreate(false)
    }

    if (loading) return <Spinner animation="border" />

    return (
        <div className="d-flex flex-nowrap m-0 p-0 h-100">
            <div id="page-content-wrapper" className="w-100 p-4">
                {key
                    ? <Dashboard />
                    : (
                        <div className="text-center">
                            <h2>Es ist kein Dashboard vorhanden</h2>
                            <Button onClick={createItem}>Neues Dashboard anlegen</Button>
                        </div>
                    )
                }
            </div>
            {!!showCreate && <NewDashboardDialog onClose={hideShowCreate} />}
        </div>
    )
}
